document.addEventListener('DOMContentLoaded', function() {
    const textSimpleMore = document.querySelector('.text-simple__content_more');
    const textSimpleContentHide = document.querySelector('.text-simple__content_hide');
  
    if (textSimpleMore) {
      textSimpleMore.addEventListener('click', function() {
        textSimpleMore.classList.toggle('active');
        textSimpleContentHide.classList.toggle('active');
      });
    }
});