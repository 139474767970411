document.addEventListener('DOMContentLoaded', () => {
    let menuButton = document.querySelector('.header-menu__mobile a');

    // Функция предотвращающая скролл
    const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
    function preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault) e.preventDefault();
        e.returnValue = false;
    }
    // Лок скролла
    function lockScroll() {
        window.addEventListener('wheel', preventDefault, { passive: false });
    }
    // Разлок скролла
    function unlockScroll() {
        window.removeEventListener('wheel', preventDefault, { passive: false });
    }

    // Переменные для обработки свайпа
    let touchStartY = 0;
    let touchEndY = 0;

    // Функция открывающая модальное окно
    window.openModal = function(button) {
        if (!button) {
            return;
        }

        // Сброс ивента для кнопки
        event.preventDefault();

        // Проверка наличия атрибута data-modal
        if (button.hasAttribute('data-modal')) {
            // Получение значения атрибута href кнопки
            let modalId = button.getAttribute('href');

            // Проверка существования элемента с указанным id
            let modalElement = document.getElementById(modalId.slice(1));
            if (!modalElement) {
                console.error(`Модального окна с id "${modalId}" не существует`);
                return;
            }

            // Лок скролла
            lockScroll();

            // Открытие модального окна, добавляя к нему класс active
            modalElement.classList.toggle('active');

            // Добавление обработчиков событий для свайпа
            modalElement.addEventListener('touchstart', (e) => {
                touchStartY = e.touches[0].clientY;
            });

            modalElement.addEventListener('touchmove', (e) => {
                touchEndY = e.touches[0].clientY;
            });

            modalElement.addEventListener('touchend', () => {
                const swipeThreshold = 50; // Порог свайпа для закрытия модального окна
                const swipeDistanceY = touchEndY - touchStartY;

                if (swipeDistanceY > swipeThreshold) {
                    // Закрыть модальное окно свайпом вниз
                    modalElement.classList.toggle('active');
                    resetMenu();

                    // Удаляем класс active у гамбургера мобильного меню
                    if (menuButton) {
                        menuButton.classList.remove('active');
                    }
                }

                // Сброс значений
                touchStartY = 0;
                touchEndY = 0;
            });

            button.classList.toggle('active');
        }
    }

    // Функция закрывающая модальное окно
    window.closeModal = function(modalId) {
        // Проверка существования элемента с указанным id
        let modalElement = document.getElementById(modalId.slice(1));
        if (!modalElement) {
            console.error(`Модального окна с id "${modalId}" не существует`);
            return;
        }

        // Разлок скролла
        unlockScroll();

        // Закрытие модального окна, удаляя класс active
        modalElement.classList.toggle('active');

        // Удаляем класс active у гамбургера мобильного меню
        if (menuButton) {
            menuButton.classList.remove('active');
        }
    }

});