document.addEventListener("DOMContentLoaded", function () {
    // Кнопка назад в шапке
    let headerBack = document.querySelector('.header-back');

    // Находим блок .mobile-menu
    let mobileMenu = document.querySelector('.mobile-menu');

    // Стек для хранения истории открытых подменю
    let menuStack = [];

    // Главное меню
    let parentMenu = mobileMenu.querySelector('.mobile-menu__parrent');

    // Находим все элементы с классом menu-item-has-children
    let parentItems = mobileMenu.querySelectorAll('.menu-item-has-children');

    // Элемент для отображения активного пункта меню
    let menuTitleElement = document.querySelector('.header-menutitle');

    // Функция для обработки клика на кнопке
    function handleButtonClick(parentItem) {
        // Скрываем все другие дочерние меню
        closeOtherMenus(parentItem.id);

        // Сохраняем текущее подменю в стеке
        menuStack.push(parentItem.id);

        // Находим соответствующий дочерний mobile-menu__child
        let childMenu = mobileMenu.querySelector('.mobile-menu__child[data-target="' + parentItem.id + '"]');

        // Переключаем видимость дочернего меню
        if (childMenu) {
            childMenu.classList.toggle('visible');
        }

        // Обновляем видимость кнопки "назад" и .header-logo__mobile
        updateBackButtonVisibility();

        // Обновляем заголовок меню
        updateMenuTitle(parentItem);
    }

    // Перебираем каждый элемент
    parentItems.forEach(function (parentItem) {
        // Находим подменю (sub-menu) внутри текущего элемента
        let subMenu = parentItem.querySelector('.sub-menu');

        if (subMenu) {
            // Создаем новый элемент mobile-menu__child
            var childMenu = document.createElement('div');
            childMenu.classList.add('mobile-menu__child');

            // Перемещаем подменю в новый элемент mobile-menu__child
            childMenu.appendChild(subMenu);

            // Добавляем атрибут data-target для привязки к родителю
            childMenu.setAttribute('data-target', parentItem.id);

            // Вставляем новый элемент mobile-menu__child в конец блока .mobile-menu
            mobileMenu.appendChild(childMenu);

            // Создаем кнопку для открытия дочернего меню
            let button = document.createElement('button');
            button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9 5L16 12L9 19" stroke="#121212" stroke-width="1.6"/></svg>';
            button.classList.add('mobile-menu__button');

            // Добавляем обработчик события клика на кнопку
            button.addEventListener('click', function () {
                handleButtonClick(parentItem);
            });

            // Вставляем кнопку перед пунктом меню
            parentItem.append(button);
        }
    });

    // Создаем кнопку "назад"
    let backButton = document.createElement('button');
    backButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 5L8 12L15 19" stroke="#121212" stroke-width="1.6"/></svg>';
    backButton.classList.add('mobile-menu__button', 'back-button');

    // Добавляем обработчик события клика на кнопку "назад"
    backButton.addEventListener('click', function () {
        // Если есть элементы в стеке, возвращаемся к предыдущему подменю
        if (menuStack.length > 0) {
            let previousMenuId = menuStack.pop();

            // Закрываем текущее подменю
            let currentChildMenu = mobileMenu.querySelector('.mobile-menu__child[data-target="' + previousMenuId + '"]');
            if (currentChildMenu) {
                currentChildMenu.classList.remove('visible');
            }

            // Открываем предыдущее подменю
            let previousChildMenu = mobileMenu.querySelector('.mobile-menu__child[data-target="' + menuStack[menuStack.length - 1] + '"]');
            if (previousChildMenu) {
                previousChildMenu.classList.add('visible');
            }
            
            // Обновляем видимость кнопки "назад" и .header-logo__mobile
            updateBackButtonVisibility();

            // Обновляем заголовок меню
            updateMenuTitleById(menuStack[menuStack.length - 1]);
        } else {
            // Обновляем заголовок меню
            updateMenuTitle();
        }
        updateAllEvents();
    });

    // Вставляем кнопку "назад" в начало блока .mobile-menu
    headerBack.append(backButton);

    // Функция для закрытия всех других дочерних меню
    function closeOtherMenus(exceptId) {
        let otherChildMenus = mobileMenu.querySelectorAll('.mobile-menu__child:not([data-target="' + exceptId + '"])');

        // Закрываем все другие дочерние меню
        otherChildMenus.forEach(function (otherChildMenu) {
            otherChildMenu.classList.remove('visible');
        });

        // Скрываем главное меню
        parentMenu.classList.add('hidden');
    }

    // Функция для обновления видимости кнопки "назад" и .header-logo__mobile
    function updateBackButtonVisibility() {
        // Проверяем, есть ли элементы в стеке
        if (menuStack.length > 0) {
            headerBack.style.display = 'block';
            document.querySelector('.header-logo__mobile').style.display = 'none';
        } else {
            headerBack.style.display = 'none';
            document.querySelector('.header-logo__mobile').style.display = 'flex';
        }
    }

    // Функция для обновления заголовка меню по элементу
    function updateMenuTitle(parentItem) {
        // Обновляем заголовок меню
        if (menuStack.length > 0) {
            menuTitleElement.textContent = parentItem.textContent.trim();
        } else {
            menuTitleElement.textContent = ''; // Очищаем заголовок, если все подменю закрыты
        }
    }

    // Функция для обновления заголовка меню по ID
    function updateMenuTitleById(menuId) {
        // Находим соответствующий элемент меню по ID
        let parentItem = mobileMenu.querySelector('#' + menuId);

        if (parentItem) {
            updateMenuTitle(parentItem);
        }
    }

    window.resetMenu = function() {
        // Скрываем кнопку "назад"
        headerBack.style.display = 'none';

        // Показываем логотип в шапке
        document.querySelector('.header-logo__mobile').style.display = 'flex';

        // Очищаем заголовок меню
        menuTitleElement.textContent = '';

        // Закрываем все подменю
        let childMenus = mobileMenu.querySelectorAll('.mobile-menu__child');
        childMenus.forEach(function (childMenu) {
            childMenu.classList.remove('visible');
        });

        updateAllEvents();

        // Очищаем стек
        menuStack = [];
    }

    // Добавляем слушатель событий на весь документ для проверки видимости подменю
    function updateAllEvents(event) {
        let isSubMenuVisible = false;

        parentItems.forEach(function (parentItem) {
            let childMenu = mobileMenu.querySelector('.mobile-menu__child[data-target="' + parentItem.id + '"]');
            if (childMenu && childMenu.classList.contains('visible')) {
                isSubMenuVisible = true;
            }
        });

        if (!isSubMenuVisible) {
            // Очищаем стек, если ни одно из подменю не видимо
            menuStack = [];
            // Обновляем заголовок меню
            updateMenuTitle();

            // Убираем hidden у главного меню
            parentMenu.classList.remove('hidden');
        }
    }
});

