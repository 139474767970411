// $(document).ready(function() {
//     let ctaFormPrivacy = $('.form-cta__full .wpforms-field-checkbox'),
//         ctaFormSubmitContainer = $('.form-cta__full .wpforms-submit-container');
//     $(ctaFormPrivacy).appendTo(ctaFormSubmitContainer);
// });

document.addEventListener('DOMContentLoaded', function() {
    // Форма в блоке CTA Full
    const ctaFormPrivacy = document.querySelector('.form-cta__full .wpforms-field-checkbox');
    const ctaFormSubmitContainer = document.querySelector('.form-cta__full .wpforms-submit-container');
  
    if (ctaFormPrivacy && ctaFormSubmitContainer) {
      ctaFormSubmitContainer.appendChild(ctaFormPrivacy);
    }

    // Форма в попапе callback
    const callbackFormPrivacy = document.querySelector('.form-callback .wpforms-field-checkbox');
    const callbackFormSubmitContainer = document.querySelector('.form-callback .wpforms-submit-container');
  
    if (callbackFormPrivacy && callbackFormSubmitContainer) {
      callbackFormSubmitContainer.appendChild(callbackFormPrivacy);
    }

    // Форма в попапе saloon
    const saloonFormPrivacy = document.querySelector('.form-saloon .wpforms-field-checkbox');
    const saloonFormSubmitContainer = document.querySelector('.form-saloon .wpforms-submit-container');
  
    if (saloonFormPrivacy && saloonFormSubmitContainer) {
      saloonFormSubmitContainer.appendChild(saloonFormPrivacy);
    }
});