document.addEventListener('DOMContentLoaded', function() {
    const seoHeading = document.querySelector('.seo-heading');
    const seoMore = document.querySelector('.seo-heading__more');
    const seoContent = document.querySelector('.seo-content');

    if (seoHeading) {
      seoHeading.addEventListener('click', function() {
        seoHeading.classList.toggle('active');
        seoMore.classList.toggle('active');
        seoContent.classList.toggle('active');
      });
    }
});