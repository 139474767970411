// Получаем все элементы с классом "faq-simple__accordion_question"
const accordionFaq = document.querySelectorAll('.faq-simple__accordion_question');

// Добавляем обработчик клика для каждого элемента
accordionFaq.forEach((question, index) => {
  question.addEventListener('click', () => {
    // Находим родительский элемент с классом "faq-simple__accordion_item"
    const accordionFaqItem = question.closest('.faq-simple__accordion_item');

    // Получаем все элементы аккордеона
    const allAccordionFaqItems = document.querySelectorAll('.faq-simple__accordion_item');

    // Закрываем все элементы аккордеона, кроме первого элемента
    allAccordionFaqItems.forEach((item, itemIndex) => {
      if (itemIndex !== index) {
        item.classList.remove('active');
      }
    });

    // Если аккордеон уже активен, удаляем класс "active", иначе добавляем его
    if (accordionFaqItem.classList.contains('active')) {
      accordionFaqItem.classList.remove('active');
    } else {
      accordionFaqItem.classList.add('active');
    }
  });

  // Открываем первый элемент аккордеона по умолчанию
  if (index === 0) {
    const accordionFaqItem = question.closest('.faq-simple__accordion_item');
    accordionFaqItem.classList.add('active');
  }
});
