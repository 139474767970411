// Фильтры
$(document).ready(function () {
    // Переменные
    const projectsFilterInput = $('#projects-filter input'),
          projectsFilterButton = $('#projects-filter button');

    // Функции
    // AJAX обработчик для фильтров (проекты)
    function filtersAJAX() {
        $.ajax({
            url: backend_data.ajax_url,
            data: filter.serialize(),
            dataType:'json',
            type:'POST',
            method:'POST',
            beforeSend:function(xhr, data){
                $('.project-list__wrap .project-list').html('<div class="search-placeholder"><span>Поиск...</span></div>');
            },
            success:function(data){
                $('.project-list__wrap .project-list').fadeIn(3000).html('')
                $('.project-list__wrap .project-list').fadeIn(3000).html(data.content)
            },
            error(xhr){
                console.log(xhr.status)
                console.log(xhr.statusText)
            }
        });
    }
  
    // События
    // Фильтры проектов
    projectsFilterInput.on('input', function(e) {
        filter = $('#projects-filter');
        filtersAJAX();
    });
    // Статус кнопки очистки фильтра
    projectsFilterButton.hide();
    projectsFilterInput.change(function(){
        if ($('#projects-filter input:checked').length > 0) {
            projectsFilterButton.show();
        } else {
            projectsFilterButton.hide();
        }
    });
    // Очистка фильтра
    projectsFilterButton.on('click', function() {
        filter = $('#projects-filter');
        filter[0].reset();
        filtersAJAX();
        $(this).hide();
    });
});