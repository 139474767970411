// themeName меняем на имя папки темы
// form#subscribe меняем на селектор формы
const formId = document.querySelector('form#subscribe');

// Отправка данных с формы подписки
if (formId) {
    $( formId ).submit(function(e) {
        e.preventDefault();
        var form = $(this);
        var data = form.serialize();
        $.ajax({
            type: 'POST',
            url: '/wp-content/themes/neo-theme/includes/subscribeForm.php',
            dataType: 'json',
            data: data,
            beforeSend: function(data) {},
            success: function(data){

            },
            error: function (xhr, ajaxOptions, thrownError) {

            },
            complete: function(data) {
                form[0].reset();
            }
        });
    });
}